<template>
  <div class="MyFooter" id="MyFooter">
    <!-- 底栏容器 -->
      <div class="footer">
        <div class="ng-promise-box">
          <div class="ng-promise">
            <ul>
              <li><router-link to="/">首页</router-link></li>
              <li><router-link to="/goods">全部商品</router-link></li>
              <li><router-link to="/help/About">关于我们</router-link></li>
              <li><router-link to="/User/order">我的订单</router-link></li>
              <li><router-link to="/help/Service">服务中心</router-link></li>
              <li><router-link to="/User">个人中心</router-link></li>
            </ul>
            <p>
              <a>客服热线：13524136915</a>
              <a>服务时间：工作日 9:30~12:00；13:30~18:30</a>
              <a>官方微信小程序：巨悠租<br></a>
            </p>
          </div>
        </div>
        <div class="mod_help">
          <p class="coty">Copyright©上海高瓴盛科技有限公司.版权所有 &copy; <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2022028076号-1</a></p>
        </div>
      </div>
    <!-- 底栏容器END -->
  </div>
</template>
<script>
export default {
  name: "MyFooter",
  data() {
    return {
    };
  },
  methods: {
    
  },
  watch: {
   
  }
};
</script>
<style>
@import '../assets/css/index.css';

</style>
<template>
  <div class="MyMenu" id="MyMenu">
    <div class="other_header" v-if="menuname == 'other'">
      <router-link to="/">
        <img src="../assets/imgs/juzlogo.png" alt />
      </router-link>
      <ul>
        <li>售前咨询： 15021648934</li>
        <li>联系客服</li>
        <li>服务中心</li>
      </ul>
    </div>
    <div class="index_header" v-if="menuname == 'index'">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" active-text-color="#409eff" router>
        <div class="logo">
          <router-link to="/">
            <img src="../assets/imgs/juzlogo.png" alt />
          </router-link>
        </div>
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/goods">全部商品</el-menu-item>
        <el-menu-item index="/help/About">关于我们</el-menu-item>
        <div class="so">
          <el-input placeholder="请输入搜索内容" v-model="search">
            <el-button slot="append" icon="el-icon-search" @click="searchClick"></el-button>
          </el-input>
        </div>
      </el-menu>
    </div>
    <div class="index_header" v-if="menuname == 'user'">
      <el-menu :default-active="activeIndex" class="el-menu-demo" active-text-color="#409eff" router>
        <div class="logo">
          <router-link to="/">
            <img src="../assets/imgs/juzlogo.png" alt />
          </router-link>
          <h2>个人中心</h2>
        </div>
        <div class="so">
          <el-input placeholder="请输入搜索内容" v-model="search">
            <el-button slot="append" icon="el-icon-search" @click="searchClick"></el-button>
          </el-input>
        </div>
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MyMenu',
  props: ['menuname'],
  data() {
    return {
      activeIndex: '', // 头部导航栏选中的标签
      search: '', // 搜索条件
      getNum: 0
    }
  },
  beforeUpdate() {},
  created() {
    this.activeIndex = this.$route.path
  },
  watch: {
    $route(to) {
      let { path } = to
      this.activeIndex = path
    }
  },
  methods: {
    // 点击搜索按钮
    searchClick() {
      if (this.search != '') {
        // 跳转到全部商品页面,并传递搜索条件
        this.$router.push({ path: '/goods', query: { search: this.search } })
        this.search = ''
      }
    }
  }
}
</script>
<style>
@import '../assets/css/index.css';
</style>

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [{
    path: '/',
    name: 'Main',
    component: () => import('../views/Main.vue'),
    children: [{
        path: '/',
        name: 'Home',
        component: () => import('../views/Home/index.vue')
      },
      {
        path: '/goods',
        name: 'Goods',
        component: () => import('../views/Goods/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/details',
        name: 'Details',
        component: () => import('../views/Goods/Details.vue')
      },
      {
        path: '/help/:name',
        name: 'Help',
        component: () => import('../views/Help/index.vue')
      },
      {
        path: '/collect',
        name: 'Collect',
        component: () => import('../views/Collect/index.vue'),
        meta: {
          requireAuth: true // 需要验证登录状态
        }
      },
      {
        path: '/ConfirmOrder',
        name: 'ConfirmOrder',
        component: () => import('../views/Order/ConfirmOrder.vue'),
        meta: {
          requireAuth: true // 需要验证登录状态
        }
      },
      {
        path: '/Enterprise',
        name: 'Enterprise',
        component: () => import('../views/Order/Enterprise.vue'),
        meta: {
          requireAuth: true // 需要验证登录状态
        }
      },
      {
        path: '/Collating',
        name: 'Collating',
        component: () => import('../views/Order/Collating.vue'),
        meta: {
          requireAuth: true // 需要验证登录状态
        }
      },
      {
        path: '/Submitted',
        name: 'Submitted',
        component: () => import('../views/Order/Submitted.vue'),
        meta: {
          requireAuth: true // 需要验证登录状态
        }
      },
      {
        path: '/User',
        name: 'User',
        component: () => import('../views/User/index.vue'),
        meta: {
          requireAuth: true // 需要验证登录状态
        },
        children: [{
            path: 'order',
            name: 'order',
            component: () => import('../views/User/item/ordermanage.vue'),
            meta: {
              requireAuth: true // 需要验证登录状态
            }
          },
          {
            path: 'order/orderdetail',
            name: 'orderdetail',
            component: () => import('../views/User/item/orderdetail.vue'),
            meta: {
              requireAuth: true // 需要验证登录状态
            }
          },
          {
            path: ':name',
            name: 'User',
            component: () => import('../views/User/index.vue'),
            meta: {
              requireAuth: true // 需要验证登录状态
            }
          }
        ]
      },

      {
        path: '/error',
        name: 'Error',
        component: () => import('../components/Error.vue')
      },


    ]
  },

  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login/index.vue'),
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('../views/Login/index.vue')
  }






]

const router = new Router({
  // base: '/dist',
  // mode: 'history',
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})


/* 由于Vue-router在3.1之后把$router.push()方法改为了Promise。所以假如没有回调函数，错误信息就会交给全局的路由错误处理。
vue-router先报了一个Uncaught(in promise)的错误(因为push没加回调) ，然后再点击路由的时候才会触发NavigationDuplicated的错误(路由出现的错误，全局错误处理打印了出来)。*/
// 禁止全局路由错误处理打印
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router